@font-face {
font-family: '__WorkSans_f11bf3';
src: url(/_next/static/media/e89d696ee8ca1e9a-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__WorkSans_f11bf3';
src: url(/_next/static/media/0a49fa5c8bdfb6de-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 500;
font-style: normal;
}@font-face {font-family: '__WorkSans_Fallback_f11bf3';src: local("Arial");ascent-override: 82.98%;descent-override: 21.68%;line-gap-override: 0.00%;size-adjust: 112.07%
}.__className_f11bf3 {font-family: '__WorkSans_f11bf3', '__WorkSans_Fallback_f11bf3'
}.__variable_f11bf3 {--font-work-sans: '__WorkSans_f11bf3', '__WorkSans_Fallback_f11bf3'
}

